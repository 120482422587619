<template>
  <div class="d-flex align-items-center min-vh-100">
    <CContainer fluid>
      <CRow class="justify-content-center">
        <CCol md="6">
          <CCard class="mx-4 mb-0">
            <CCardBody class="p-4">
              <CForm @submit.prevent="register" method="POST">
                <h1>Register</h1>
                <p class="text-muted">Create your account</p>
                <CInput
                  placeholder="ID"
                  prependHtml="<i class='cui-fingerprint'></i>"
                  autocomplete="id"
                  v-model="id"
                >
                  <template #prepend-content
                    ><CIcon name="cil-fingerprint"
                  /></template>
                </CInput>
                <CInput
                  placeholder="Partner ID"
                  prependHtml="<i class='cui-building'></i>"
                  autocomplete="partnerid"
                  v-model="partnerid"
                >
                  <template #prepend-content
                    ><CIcon name="cil-building"
                  /></template>
                </CInput>
                <CInput
                  placeholder="First name"
                  prependHtml="<i class='cui-user'></i>"
                  autocomplete="firstname"
                  v-model="firstname"
                >
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CInput>
                <CInput
                  placeholder="Last name"
                  prependHtml="<i class='cui-user'></i>"
                  autocomplete="lastname"
                  v-model="lastname"
                >
                  <template #prepend-content
                    ><CIcon name="cil-user"
                  /></template>
                </CInput>
                <CInput
                  placeholder="Email"
                  prepend="@"
                  autocomplete="email"
                  v-model="email"
                />
                <CInput
                  placeholder="Mobile number"
                  prependHtml="<i class='cui-mobile'></i>"
                  autocomplete="mobilenumber"
                  v-model="mobilenumber"
                >
                  <template #prepend-content
                    ><CIcon name="cil-mobile"
                  /></template>
                </CInput>
                <CInput
                  placeholder="Company"
                  prependHtml="<i class='cui-building'></i>"
                  autocomplete="company"
                  v-model="company"
                >
                  <template #prepend-content
                    ><CIcon name="cil-building"
                  /></template>
                </CInput>
                <CInput
                  placeholder="Password"
                  type="password"
                  prependHtml="<i class='cui-lock-locked'></i>"
                  autocomplete="new-password"
                  v-model="password"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <CInput
                  placeholder="Repeat password"
                  type="password"
                  prependHtml="<i class='cui-lock-locked'></i>"
                  autocomplete="new-password"
                  class="mb-4"
                  v-model="password_confirmation"
                >
                  <template #prepend-content
                    ><CIcon name="cil-lock-locked"
                  /></template>
                </CInput>
                <CButton type="submit" color="success" block
                  >Create Account</CButton
                >
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

  <script>
import axios from "axios";
export default {
  data() {
    return {
      firstname: "",
      lastname: "",
      id: "",
      partnerid: "",
      company: "",
      mobilenumber: "",
      email: "",
      password: "",
      password_confirmation: "",
    };
  },
  methods: {
    register() {
      var self = this;
      axios
        .post(this.$apiAdress + "/v1/account", {
          lastname: self.lastname,
          firstname: self.firstname,
          mobilenumber: self.mobilenumber,
          email: self.email,
          password: self.password,
          company: self.company,
          id: self.id,
          partnerid: self.partnerid
        })
        .then(function (response) {
          self.firstname = "";
          self.email = "";
          self.password = "";
          self.password_confirmation = "";
          self.$router.push({ path: "/auth" });
        })
        .catch(function (error) {
        });
    },
  },
};
</script>
